<template>
    <!-- COMPONENT -->
    <!--*******************************************-->

    <content-block
        :title="group.title"
        outlined
        :expanded="!linked_tracking_component"
    >
        <svgicon
            slot="title_content_prefix"
            name="jira-epic"
            class="jira-icon"
        />
        <div v-if="linked_tracking_component" slot="right" class="controls">
            <el-button
                size="mini"
                icon="el-icon-connection"
                @click="handleViewComponent(linked_tracking_component)"
            >
                {{ linked_tracking_component?.name }}
            </el-button>
            <el-alert type="success" show-icon :closable="false">
                Linked
            </el-alert>
        </div>
        <template v-else>
            <el-form
                ref="form"
                auto-complete="on"
                :model="form"
                label-width="160px"
                class="form-wrapper"
            >
                <el-form-item label="New component">
                    <el-switch v-model="form.is_new" />
                </el-form-item>
                <el-form-item
                    v-if="linked_retainer_component"
                    label="Linked to retainer"
                >
                    {{ linked_retainer_component.name }}
                </el-form-item>

                <template v-if="form.is_new">
                    <el-form-item
                        label="Name"
                        prop="name"
                        :rules="{
                            required: true,
                            message: 'Name is required',
                            trigger: 'blur',
                        }"
                    >
                        <el-input v-model="form.name" />
                    </el-form-item>
                    <el-form-item
                        label="Start date"
                        prop="start_date"
                        :rules="{
                            required: true,
                            message: ' ',
                            trigger: 'blur',
                        }"
                    >
                        <el-date-picker v-model="form.start_date" />
                    </el-form-item>
                    <el-form-item
                        label="End date"
                        prop="end_date"
                        :rules="{
                            required: true,
                            message: ' ',
                            trigger: 'blur',
                        }"
                    >
                        <el-date-picker v-model="form.end_date" />
                    </el-form-item>

                    <template v-if="!linked_retainer_component">
                        <el-form-item label="Round time to" prop="round">
                            <el-input
                                v-model="form.round"
                                placeholder="minutes"
                                clearable
                            >
                                <template slot="append">
                                    {{ 'minute' | pluralize(form.round) }}
                                </template>
                            </el-input>
                        </el-form-item>

                        <el-form-item
                            v-if="form.round > 0"
                            label="Round after"
                            prop="min"
                        >
                            <el-input
                                v-model="form.min"
                                placeholder="minutes"
                                clearable
                            >
                                <template slot="append">
                                    {{ 'minute' | pluralize(form.min) }}
                                </template>
                            </el-input>
                        </el-form-item>
                    </template>
                </template>
                <template v-else>
                    <el-form-item
                        label="Component"
                        prop="id"
                        :rules="{
                            required: true,
                            message: 'Component is required',
                            trigger: 'blur',
                        }"
                    >
                        <el-select v-model="form.id">
                            <template>
                                <el-option
                                    v-for="c of linkable_components"
                                    :key="c.id"
                                    :label="c.name"
                                    :value="c.id"
                                />
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Increase hours">
                        <el-checkbox v-model="form.add_hours">
                            Add {{ group_time_hours }}
                            {{ 'hour' | pluralize(group_time_hours) }} to
                            component
                        </el-checkbox>
                    </el-form-item>
                </template>
            </el-form>

            <div class="section-footer">
                <el-button
                    :loading="loading"
                    size="mini"
                    icon="el-icon-bottom"
                    @click="handleAssignComponent"
                >
                    Assign component
                </el-button>
            </div>
        </template>
    </content-block>
</template>

<script>
import ContentBlock from '@/components/blocks/ContentBlock';

import {momentWithTz} from '@/utils';
import {timestamp2moment} from '@/filters';
import {generateUUID} from '@/utils/firebase';

import firebase from 'firebase/app';

export default {
    name: 'estimate-component-link',
    components: {ContentBlock},
    props: {
        estimate: {
            type: Object,
            required: true,
        },
        group: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            form: {
                id: null,
                status: 'active',
                is_new: true,
                name: this.group.title,
                start_date: null,
                end_date: null,
                round: null,
                min: null,
                add_hours: true,
            },
        };
    },
    computed: {
        project() {
            return this.$store.getters.projectWithId(this.estimate.project);
        },
        stage() {
            return this.$store.getters.stageWithId(this.estimate.stage);
        },
        group_time_hours() {
            return (
                this.group.qty *
                (this.estimate.unit === 'day' ? this.estimate.hours_per_day : 1)
            );
        },
        group_time_millis() {
            return this.group_time_hours * 3600000;
        },

        linkable_components() {
            // all components for the selected stage are linkable
            if (this.stage) {
                return this.$store.getters
                    .componentsForStageWithId(this.stage.id)
                    .filter((component) => !component.recurring);
            }
            return [];
        },
        linked_tracking_component() {
            if (this.group?.ref_links?.['tracking_component']) {
                return this.$store.getters.componentWithId(
                    this.group.ref_links['tracking_component']
                );
            }
            return null;
        },
        linked_retainer_component() {
            if (this.group?.ref_links?.['retainer_component']) {
                return this.$store.getters.componentWithId(
                    this.group.ref_links['retainer_component']
                );
            }
            return null;
        },
    },
    methods: {
        handleAssignComponent() {
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    this.loading = true;

                    let component_ref = null;

                    if (this.form.is_new) {
                        const new_component = {
                            name: this.form.name,
                            status: 'active',
                            recurring: false,

                            project: this.$fire.doc(
                                `projects/${this.project.id}`
                            ),
                            stage: this.$fire.doc(`stages/${this.stage.id}`),

                            start_date: this.form.start_date,
                            end_date: this.form.end_date,

                            time_estimate: this.group_time_millis,

                            ref_links: {
                                // Create a link between the new component and the estimate group
                                estimates: [
                                    this.$fire.doc(
                                        `estimates/${this.estimate.id}`
                                    ),
                                ],
                                // If the retainer component was linked
                                ...(this.linked_retainer_component && {
                                    // Create a link between the new component and the retainer component
                                    // This new component will be considered a "sub" component
                                    parent_component: this.$fire.doc(
                                        `components/${this.linked_retainer_component.id}`
                                    ),
                                }),
                            },

                            // Add rounding if specified
                            ...(this.form.round && {
                                round: this.form.round * 60000,
                                min: this.form.min * 60000,
                            }),
                        };

                        const snapshot = await this.$store.dispatch(
                            'addComponent',
                            new_component
                        );

                        component_ref = this.$fire.doc(
                            `components/${snapshot.id}`
                        );

                        // if linked to a recurring component, make an adjustment to subtract the time estimate
                        if (this.linked_retainer_component) {
                            await this.adjustRecurringComponent(component_ref);
                        }
                    } else {
                        // use existing component
                        const component = this.$store.getters.componentWithId(
                            this.form.id
                        );

                        component_ref = this.$fire.doc(
                            `components/${component.id}`
                        );

                        const update_data = {};
                        // If the component is not active, update status to make it available
                        if (component.status !== 'active') {
                            update_data.status = 'active';
                        }
                        // If option is selected, increase hours by group qty
                        if (this.form.add_hours) {
                            update_data.time_estimate = firebase.firestore.FieldValue.increment(
                                this.group_time_millis
                            );
                        }
                        if (Object.keys(update_data).length) {
                            await this.$store.dispatch(
                                'updateComponentWithId',
                                {
                                    component_id: component.id,
                                    payload: update_data,
                                }
                            );
                        }

                        // Create a link between the component and the estimate group
                        const existing_estimate_refs =
                            component.estimates ?? [];
                        await this.$store.dispatch('updateComponentRefLink', {
                            component_id: component.id,
                            ref_link: {
                                key: 'estimates',
                                ref: [
                                    ...existing_estimate_refs,
                                    this.$fire.doc(this.group.estimate),
                                ],
                            },
                        });

                        // If there is a linked retainer
                        // This component will be considered a "sub" component
                        if (this.linked_retainer_component) {
                            await this.$store.dispatch(
                                'updateComponentRefLink',
                                {
                                    component_id: component.id,
                                    ref_link: {
                                        key: 'parent_component',
                                        ref: this.$fire.doc(
                                            `components/${this.linked_retainer_component.id}`
                                        ),
                                    },
                                }
                            );
                        }
                    }

                    // set component ref on estimate group
                    await this.$store.dispatch('updateEstimateGroupRefLink', {
                        group_id: this.group.id,
                        ref_link: {
                            key: 'tracking_component',
                            ref: component_ref,
                        },
                    });

                    this.loading = false;
                } else {
                    console.log('Form not valid...');
                    return false;
                }
            });
        },
        async adjustRecurringComponent(tracking_component_ref) {
            // get blocks for linked recurring component
            const blocks = [];
            const blocks_ref = this.$fire
                .collection('component_recurring_blocks')
                .where(
                    'component',
                    '==',
                    this.$fire.doc(
                        `components/${this.linked_retainer_component.id}`
                    )
                )
                .where('start_date', '<', new Date())
                .orderBy('start_date', 'asc');

            const blocks_snapshot = await blocks_ref.get();
            blocks_snapshot.forEach((doc) => {
                blocks.push({...doc.data(), id: doc.id});
            });

            if (blocks.length) {
                // find block containing component start date, or nearest
                const start_date = momentWithTz(this.form.start_date);
                let selected_block = null;
                for (let i = blocks.length - 1; i >= 0; --i) {
                    if (
                        start_date.isSameOrAfter(
                            timestamp2moment(blocks[i].start_date)
                        )
                    ) {
                        selected_block = blocks[i];
                        break;
                    }
                }
                if (!selected_block) {
                    // start date must be before first block, use first
                    selected_block = blocks[0];
                }

                this.$fire
                    .doc(`component_recurring_blocks/${selected_block.id}`)
                    .update({
                        time_adjustment: firebase.firestore.FieldValue.arrayUnion(
                            {
                                id: generateUUID(),
                                description: `${this.estimate.ref} - ${this.group.title}`,
                                time: -this.group_time_millis,
                                adjusted_at: new Date(),
                                'ref_links.estimate_groups': firebase.firestore.FieldValue.arrayUnion(
                                    this.$fire.doc(
                                        `estimate_groups/${this.group.id}`
                                    )
                                ),
                                'ref_links.tracking_component': tracking_component_ref,
                            }
                        ),
                    });
            }
        },
        handleViewComponent(component) {
            this.$router.push({
                name: 'component_detail',
                params: {
                    project_id: this.$options.filters.fireRef2id(
                        component.project
                    ),
                    stage_id: this.$options.filters.fireRef2id(component.stage),
                    component_id: component.id,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.el-date-editor.el-input {
    width: 100%;
}
.controls {
    display: flex;
    flex-direction: row;

    .el-button {
        margin-right: 10px;
    }
}
.jira-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

::v-deep {
    .el-form-item__error {
        left: 0 !important;
        right: auto;
    }
    .el-checkbox {
        .el-checkbox__label {
            font-size: 11px;
            opacity: 0.3;
            color: $black;
        }
        &.is-checked .el-checkbox__label {
            opacity: 1;
        }
    }
}
</style>
